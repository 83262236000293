<template>
    <div class="row">
        <div class="col-12 col-lg-6 col-xl row">
            <h1> {{ is_loading }} </h1>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
    name: "test",
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            is_loading: "app/getLoading",
        })
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
        ...mapActions({
        })
    }
};
</script>